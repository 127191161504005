import React from "react";

const siteMetadata = {
  title: "m2",
  description:
    "m2 entwickelt zeitgenössische Gebäude, die für Generationen gebaut werden.",
  author: "https://www.csite.at/",
  siteUrl: "https://www.m2.at/",
  image: "https://csite.fra1.cdn.digitaloceanspaces.com/studio-furore/m2/m2_logo_bg_black.PNG",
};

const Seo = ({ description, title, children }) => {
  title = siteMetadata?.title + (title ? ` | ${title}` : "");
  const metaDescription = description || siteMetadata.description;
  const metaImage = {
    src: siteMetadata.image,
    width: 1200,
    height: 630,
  };

  return (
    <>
      <html lang="de" />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteMetadata?.author || ``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {metaImage ? (
        <>
          <meta property="og:image" content={metaImage?.src} />
          <meta property="og:image:width" content={metaImage?.width} />
          <meta property="og:image:height" content={metaImage?.height} />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      ) : (
        <meta name="twitter:card" content="summary" />
      )}
      {children}
    </>
  );
};
export default Seo;
