import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { content } from "./layout.module.scss";
import Seo from "../seo/seo";

const Layout = ({ children }) => {
  return (
    <>
      <Seo />
      <main className={content}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
