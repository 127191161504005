import { footer, contactInfo, legalLinks } from "./footer.module.scss";
import { Link } from "gatsby";
import React from "react";

const year = new Date().getFullYear();
const Footer = () => {
  return (
    <footer id="footer" className={footer}>
      <div className={contactInfo}>
        <p>m2 Immobilien GmbH</p>
        <p>Liebiggasse 9, 8010 Graz</p>
        <p><a href="mailto:hello@m2.at">hello@m2.at</a></p>
      </div>
      <div className={legalLinks}>
        {/* <Link to="/datenschutz/">Datenschutz</Link> */}
        <Link to="/impressum/">Impressum</Link>
      </div>
    </footer>
  );
};

export default Footer;
